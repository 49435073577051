import React, { useRef, useState, useEffect } from "react";

import expand from "../../assets/icons/expand.svg";
import StyledTopic from "./styles";

const color = "283278";

const plusIcon = () => {
  return (
    <img
      src={`https://img.icons8.com/ios/100/${color}/plus-math--v1.png`}
      alt="plus-math--v1"
    />
  );
};

const minusIcon = () => {
  return (
    <img
      src={`https://img.icons8.com/ios/100/${color}/minus-math.png`}
      alt="minus-math--v1"
    />
  );
};
interface ITopicProps {
  title: string;
  body: string;
  index: number;
  indexColorRatio: number;
}

const Topic: React.FC<ITopicProps> = ({
  title,
  body,
  index,
  indexColorRatio,
}) => {
  const [expandedTopic, setExpandedTopic] = useState<boolean>(false);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const overflowingText = useRef<HTMLParagraphElement | null>(null);

  // console.log(index);

  const convertedString = (name: string) =>
    name
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/&/g, "and")
      .replace(",", "");

  useEffect(() => {
    const calcTextHeight = () => {
      if (overflowingText.current) {
        if (expandedTopic) {
          setScrollHeight(overflowingText.current.scrollHeight);
        } else {
          setScrollHeight(0);
        }
      }
    };
    calcTextHeight();

    window.addEventListener("resize", calcTextHeight);

    return () => window.removeEventListener("resize", calcTextHeight);
  }, [expandedTopic, overflowingText]);

  return (
    <>
      <StyledTopic
        id={convertedString(title)}
        className={`${index % 2 === 0 ? "row1" : "row2"}`}
        style={{
          backgroundColor: `hwb(${360 * indexColorRatio} 70% 5% / 1)`,
        }}
      >
        <div
          className="topic-header--wrapper"
          role="button"
          onClick={() => setExpandedTopic(!expandedTopic)}
        >
          <h3 className="topic-header">{title}</h3>
          <button
            type="button"
            className={`topic-expand--btn ${expandedTopic ? "minus" : "plus"}`}
          >
            {expandedTopic ? minusIcon() : plusIcon()}
          </button>
        </div>
        <div
          className="topic-content--wrapper"
          style={{ height: `${scrollHeight}px` }}
        >
          <p className="topic-content" ref={overflowingText}>
            {body}
          </p>
        </div>
      </StyledTopic>
    </>
  );
};

export default Topic;
