import React, { useRef, useEffect, useState } from "react";
import { InlineWidget, PopupButton } from "react-calendly";

import ProfilePicture from "./assets/images/profile.webp";
import BACPLogo from "./assets/images/BACP Logo - 394853.png";
import RDCPLogo from "./assets/images/Logo - RCDP.png";
import PhoneIcon from "./assets/icons/filled-icons8-phone-100.png";
import EmailIcon from "./assets/icons/filled-icons8-email-100.png";
import FeesIcon from "./assets/icons/icons8-money-100.png";
import ContactIcon from "./assets/icons/icons8-contact-100.png";
import UrgentHelpIcon from "./assets/icons/icons8-hug-100.png";
import ForwardArrowIcon from "./assets/icons/icons8-forward-100.png";
import FilledQuotes from "./assets/icons/quotations.svg";

import StyledLayout from "./styles";
import Topic from "./components/Topic/Topic";

import Header from "./components/Header";
import DesktopNav from "./components/DesktopNav";

// @media only screen and (min-width: 600px) {
const App: React.FC = () => {
  // const [openCarousel, setOpenCarousel] = useState<boolean>(false);
  const headerRef = useRef<HTMLElement | null>(null);
  // const menuRef = useRef<HTMLElement | null>(null);

  const [navState, setNavState] = useState<"mobile" | "desktop">("desktop");

  const convertedString = (name: string) => {
    return name.toLowerCase().replace(/\s+/g, "-").replace(/&/g, "and");
  };

  useEffect(() => {
    const calcHeaderHeight = () => {
      if (headerRef.current) {
        const headerHeight = headerRef.current.offsetHeight;
        const sections = document.querySelectorAll("section");
        const contact = document.getElementById("contact");

        sections.forEach((section) => {
          section.style.scrollMarginTop = `${headerHeight}px`;
        });

        if (contact) {
          contact.style.scrollMarginTop = `${headerHeight}px`;
        }
      }
    };
    calcHeaderHeight();

    window.addEventListener("resize", calcHeaderHeight);

    return () => window.removeEventListener("resize", calcHeaderHeight);
  }, [headerRef]);

  const headers = [
    "Introduction",
    "About Me",
    "Services",
    "Testimonials",
    "Contact & Fees",
    "Urgent Help",
    "Blog",
  ];

  type Topics = {
    [key: string]: string;
  };

  const topics: Topics = {
    "Stress, Anxiety and Depression":
      "In today’s fast-paced and demanding world, often fuelled by social media, stress, anxiety and depression have become commonplace challenges that many face.  I am here to help you manage and overcome emotional hurdles by providing evidence-based therapeutic approaches.  Together, we will identify the root cause of your unhappiness and develop personalised strategies to build resilience, enhance coping mechanisms and restore balance and harmony to your life.",
    Relationships:
      "Friends are our chosen family and navigating these relationships as well as family, romantic and professional relationships can be both exhilarating and challenging. Together we will work towards establishing healthy boundaries, improving communication and fostering more fulfilling and meaningful connections. ",
    "Bereavement and Loss":
      "If you are struggling with the loss of a loved one, or somebody with whom you had a difficult relationship, this can be an unbearably lonely time. I offer a compassionate space for you in which to grieve and find meaning as you process your pain.  Through therapy, we will navigate the various stages of grief, process your emotions and work towards developing healthy coping strategies. This process will help you to move forward while acknowledging and learning to value your memories.",
    Careers:
      "Careers play a significant role in our lives, shaping our identity and providing a sense of purpose.  If you are feeling stuck, dissatisfied or uncertain about your career path, I can help you gain clarity, set achievable goals and explore possibilities that align with your values, skills and passions.  Together we will work towards creating a fulfilling and satisfying career.",
    Loneliness:
      "Loneliness is all too often overwhelming and isolating.  I offer a warm and supportive space for those struggling with loneliness to explore the underlying causes, develop self-compassion and build a fulfilling social support system.  Through therapy, we will address any self-limiting beliefs, develop effective communication skills, and help you to cultivate meaningful connections.",
    Expats:
      "One of my specialisms is English-speaking therapy for expats. While born and bred in London, I have lived abroad for many years.  As a result, I understand the unique challenges faced when adapting to new cultures, lifestyles and environments.  I offer a safe space where expats can explore their emotions, navigate cultural differences and develop strategies in order to thrive in their new surroundings.  Together, we will work on building a strong foundation for personal growth and creating a sense of belonging in your new environment.",
  };

  const jumpTo = (section: string) => {
    const element = document.getElementById(convertedString(section));
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }

    if (section === "Blog") {
      window.open("https://lucybellocounselling.blogspot.com", "_blank");
    }
  };

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [aspectRatio, setAspectRatio] = useState<number>(
    window.innerWidth / window.innerHeight
  );

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setAspectRatio(window.innerWidth / window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // @media only screen and (min-width: 600px) {

  return (
    <StyledLayout className={`${navState}`}>
      {/* <header ref={headerRef}>
        <a href="" className="site--anchor">
          <img
            src={SiteTitle}
            className="site-title"
            alt="Lucy Bello Counselling title."
            aria-label="site title"
          />
        </a> */}
      {/* {windowWidth > 600 ? (
        <DesktopNav headers={headers} jumpTo={jumpTo} />
      ) : (
        <Header headers={headers} jumpTo={jumpTo} />
      )} */}
      <Header
        headerRef={headerRef}
        headers={headers}
        jumpTo={jumpTo}
        navState={navState}
        setNavState={setNavState}
      />
      {/* </header> */}
      <main>
        <section id="introduction" aria-label="landscape-of-calming-lake">
          <div className="parallax">
            <div className="introduction-header">
              <p className="introduction-quote">
                Get in touch and let's talk.{" "}
              </p>
            </div>
          </div>

          <p className="introduction-body">
            I am a dedicated and compassionate English-speaking counsellor and
            therapist, specialising in areas that include counselling for
            expats. I am also experienced in relationship counselling,
            bereavement, loss and loneliness. As a trained therapist I naturally
            am dedicated to general issues arising from stress, anxiety and
            depression.
            <br />
            <br />
            <span>
              I offer remote sessions via Zoom or WhatsApp. Please take the
              first (and often most difficult) step by getting in touch so we
              can start our journey together. Contact me today at{" "}
              <a href="tel:+447787283895" className="inline-ref">
                +44 7787 283895
              </a>
              .
            </span>
            <br />
            When life presents us with challenges and difficulties, we can
            sometimes navigate these issues with the help of friends and family.
            However, there are moments when we may feel stuck or overwhelmed. In
            such times, I can provide a fresh perspective, enhancing
            self-awareness, acceptance and decision making.
            <br />
            <br />
            Therapy offers a confidential space to explore your current
            challenges as well as look into your experiences and emotions.
            Together we will develop practical skills to help reduce your
            anxiety and build the resilience that you need for improved
            self-confidence and overall well-being.
          </p>
        </section>
        <section id="about-me">
          <h2>About me</h2>
          <img
            src={ProfilePicture}
            alt="Profile of Lucy"
            className="profile-picture"
          />
          <p className="about-me-body">
            Having lived abroad for many years, I qualified as a career coach
            and mentor on my return to the UK. My ime spent overseas has allowed
            me to train and specialise as an English-speaking therapist for
            expats. This has proven to be extemely valuable, both for me and for
            my many clients. These skills allow you to gain a clearer
            perspective of what is going on as well as enabling you to move
            forward in both your personal and professional life.
            <br />
            <br /> I have worked privately with a wide range of clients as well
            as volunteering with Young Minds, Princes Trust and most recently
            The Mulberry Centre as a counsellor supporting cancer patients,
            their carers and family, and the bereaved. <br />
            <br />I am a qualified integrative counsellor, registered with the
            British Association for Counselling and Psychotherapy (BACP) and am
            also a qualified Careers Coach and a registered member of the Career
            Development Institute (CDI). I am registered with the Information
            Commissioner's Office (ICO) ensuring best practice regarding
            personal information (GDPR).
          </p>
        </section>
        <section id="services">
          <div className="services--main">
            <h2>Services</h2>
            <hr />
            <p id="services-body">
              In our counselling sessions, I utilize an integrative and
              client-centred approach, tailoring the work to your unique needs
              and goals and at a pace that is comfortable for you. Together we
              will embark on a journey of self-discovery, growth and
              transformation, enabling you to create positive and meaningful
              change in your life. I draw upon various therapeutic modalities
              including Psychodynamic, Acceptance and Commitment Therapy (ACT),
              Cognitive Behavioural Therapy (CBT), mindfulness-based techniques
              and solution focused therapy to create a holistic and empowering
              experience.
            </p>
          </div>
          <div className="calendar">
            <PopupButton
              url="https://calendly.com/lucybellocounselling/50-minute-therapy-session?hide_gdpr_banner=1"
              text="Book Appointment"
              rootElement={document.getElementById("root") as HTMLDivElement}
            />
          </div>
        </section>
        <section id="topics">
          {Object.keys(topics).map((key, index) => (
            <Topic
              key={key}
              title={key}
              body={topics[key]}
              index={index}
              indexColorRatio={index / Object.keys(topics).length}
            />
          ))}
        </section>
        <section id="testimonials">
          <h2>Testimonials</h2>
          <div className="testimonial" id="first">
            <img src={FilledQuotes} alt="Quotation Mark" />
            <p>
              Such a brilliant experience and it really helped to separate
              myself from my illness.
            </p>
          </div>
          <div className="testimonial" id="second">
            <img src={FilledQuotes} alt="Quotation Mark" />
            <p>
              Lucy was very professional and supportive with great attention to
              detail and helped me change the way I viewed my situation.
            </p>
          </div>
          <div className="testimonial" id="third">
            <img src={FilledQuotes} alt="Quotation Mark" />
            <p>
              Very professional and caring person who makes therapy feels so
              natural and easy. She's thoughtful and helpful.
            </p>
          </div>
        </section>
        <section id="contact-and-fees">
          <div className="contact-and-fees">
            <div id="contact" className="contact-and-fees--wrapper">
              <div className="contact-and-fees--header list--bullet bullet--large">
                <img src={ContactIcon} />
                <h2>Contact</h2>
              </div>
              <div className="contact-and-fees--content">
                <ul>
                  <li className="list--bullet bullet--small">
                    <img src={EmailIcon} />

                    <a href="mailto:lucybellocounselling@gmail.com">
                      <p>lucybellocounselling@gmail.com</p>
                    </a>
                  </li>
                  <li className="list--bullet bullet--small">
                    <img src={PhoneIcon} />
                    <a href="tel:+447787283895">
                      <p>07787 283895</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="fees" className="contact-and-fees--wrapper">
              <div className="contact-and-fees--header list--bullet bullet--large">
                <img src={FeesIcon} />
                <h2>Fees</h2>
              </div>

              <div className="contact-and-fees--content">
                <ul>
                  <li className="list--bullet bullet--small">
                    <img src={ForwardArrowIcon} className="bullet-icon" />
                    <p>
                      Individual Remote Counselling £70 per 50 minute session.
                    </p>
                  </li>
                  <li className="list--bullet bullet--small">
                    <img src={ForwardArrowIcon} className="bullet-icon" />
                    <p>Counselling Students Welcome.</p>
                  </li>
                  <li className="list--bullet bullet--small">
                    <img src={ForwardArrowIcon} className="bullet-icon" />
                    <p>Concessions in certain circumstances are available.</p>
                  </li>
                </ul>
              </div>
            </div>
            <div id="urgent-help" className="contact-and-fees--wrapper">
              <div className="contact-and-fees--header list--bullet bullet--large">
                <img src={UrgentHelpIcon} />
                <h2>Urgent Help</h2>
              </div>
              <div className="contact-and-fees--content">
                <p className="urgent-msg">
                  <i>
                    <strong>Emergency -</strong> If you are worried about
                    immediate risk of harm please visit your nearest A&E and ask
                    to see the duty psychiatrist <b>OR</b> contact one of the
                    following:
                  </i>
                </p>
                <br />
                <ul>
                  <li className="contact-point">
                    <h6>Samaritans:</h6> <p>116 123</p>
                  </li>
                  <li className="contact-point">
                    <h6>Emergency services:</h6> <p>999</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="calendar-inline">
            <InlineWidget
              url="https://calendly.com/lucybellocounselling/50-minute-therapy-session?hide_gdpr_banner=1"
              // styles={{ height: "100%" }}
            />
          </div>
        </section>
        <section id="credentials">
          <img src={BACPLogo} alt="BACP Logo" className="credential-logo" />
          <img src={RDCPLogo} alt="RDCP Logo" className="credential-logo" />
        </section>
      </main>
      <footer>
        <p id="copyright">
          ©2023 by Lucy Bello Counselling . Designed by NessDesigns
        </p>
      </footer>
    </StyledLayout>
  );
};

export default App;
