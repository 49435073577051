import React, { useState, useRef, useEffect } from "react";
import StyledHeader from "./styles";

import SiteTitle from "../../assets/icons/site-title.svg";

interface IHeaderProps {
  headerRef: React.RefObject<HTMLElement>;
  headers: string[];
  jumpTo: (section: string) => void;
  navState: "mobile" | "desktop";
  setNavState: CallableFunction;
}

const Header: React.FC<IHeaderProps> = ({
  headerRef,
  headers,
  jumpTo,
  navState,
  setNavState,
}) => {
  const [openCarousel, setOpenCarousel] = useState<boolean>(false);
  const menuRef = useRef<HTMLElement | null>(null);
  const carouselRef = useRef<HTMLUListElement | null>(null);

  // -------------------------------------------------------
  // Click outside menu to close on IOS
  useEffect(() => {
    const handleTouchEnd = (event: TouchEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        // setOpenCarousel(false);
      }
    };

    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [aspectRatio, setAspectRatio] = useState<number>(
    window.innerWidth / window.innerHeight
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setAspectRatio(window.innerWidth / window.innerHeight);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleOverflow = () => {
      const { current } = carouselRef;
      if (current) {
        const isOverflowing = aspectRatio <= 3 / 4;

        if (isOverflowing) {
          setNavState("mobile");
        } else {
          setNavState("desktop");
        }
      }
    };

    handleOverflow();

    window.addEventListener("resize", handleOverflow);

    return () => {
      window.removeEventListener("resize", handleOverflow);
    };
  }, [carouselRef, aspectRatio]);

  return (
    <StyledHeader ref={headerRef} className={navState}>
      <a href="" className="site--anchor">
        <img
          src={SiteTitle}
          className="site-title"
          alt="Lucy Bello Counselling title."
          aria-label="site title"
        />
      </a>
      <button
        role="navigation"
        onClick={() => {
          jumpTo("contact");
          // setOpenCarousel(false);
        }}
        className={`contact--anchor${openCarousel ? " behind" : ""}`}
      >
        Contact Today
      </button>

      <nav className={`site-navigation ${navState}`} ref={menuRef}>
        {/* {windowWidth > 600 ? DesktopNav() : MobileNav()} */}
        <input
          className="checkbox mobile"
          type="checkbox"
          name=""
          id=""
          checked={openCarousel}
          onChange={() => setOpenCarousel(!openCarousel)}
        />
        <div className="hamburger-lines mobile">
          <span className="line line1" />
          <span className="line line2" />
          <span className="line line3" />
        </div>
        <ul className={`site-carousel-mobile mobile`}>
          {headers.map((section: string) => (
            <li key={section} className="nav-opt">
              <a
                onClick={() => {
                  jumpTo(section);
                  setOpenCarousel(false);
                }}
              >
                {section}
              </a>
            </li>
          ))}
        </ul>
        <ul className={`site-carousel-desktop desktop`} ref={carouselRef}>
          {headers.map((section: string) => (
            <li key={section} className="nav-opt">
              <a
                onClick={() => {
                  jumpTo(section);
                  setOpenCarousel(false);
                }}
              >
                {section}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </StyledHeader>
  );
};

export default Header;
