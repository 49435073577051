import styled from "styled-components";

import background from "./assets/images/background.webp";

export default styled.div`
  /* font-size: 1.25vw; */

  /* @media only screen and (min-width: 601px) {
    font-size: 0.75vh;
    font-size: min(0.75vh, 6px);
  } */

  /* @media only screen and (max-width: 600px) {
    font-size: 1.25vw;
  } */

  @media only screen and (min-aspect-ratio: 4/3) {
    font-size: 0.75vh;
  }

  @media only screen and (min-width: 800px) {
    font-size: 6px;
  }

  @media only screen and (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
    font-size: 0.6vh;
  }

  @media only screen and (max-aspect-ratio: 3/4) {
    font-size: 1.25vw;
  }

  .parallax {
    align-self: stretch;
    background-image: url(${background});

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  section {
    width: 100%;
  }

  main {
    font-size: 0.9em;
    display: flex;
    flex-direction: column;

    gap: 7em;
    align-items: center;

    #introduction {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #e0e4f3;

      .introduction-header {
        font-weight: 500;
        width: 60%;
        margin: 10em 0;
        text-align: center;

        .introduction-quote {
          font-weight: 600;
          font-size: 4em;
        }
      }

      .introduction-body {
        display: flex;
        flex-direction: column;

        padding: 2.5em 0;
        width: 80%;

        max-width: 100em;

        justify-content: center;
        text-align: center;
      }
    }

    #about-me {
      display: flex;
      flex-direction: column;

      align-items: center;
      gap: 3.5em;

      .profile-picture {
        width: 45em;
        height: 45em;
        border-radius: 50%;
      }

      .about-me-body {
        max-width: 100em;
        width: 80%;
        /* text-align: justify; */

        display: flex;
        justify-content: center;
        /* padding: 2.5em 0; */
      }
    }

    #services {
      display: flex;
      background-color: #e0e4f3;

      margin-top: 3em;
      padding: 3em 3em 3em 5em;
      width: 80%;

      max-width: 250em;

      box-shadow: #f9d7b9 -3em -3em 0px 0px;
      position: relative;

      .services--main {
        display: flex;
        flex-direction: column;
        text-align: left;
      }

      .calendar {
        position: absolute;
        right: 3em;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-basis: 100%;
      }
    }

    #topics {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas: "col1 col2" "col1 col2" "col1 col2";
      /* align-items: flex-start; */

      .col1 {
        grid-area: col1;
      }

      .col2 {
        grid-area: col2;
      }
    }

    #testimonials {
      display: flex;
      flex-direction: column;
      margin: 0 2em;

      /* .testimonials--container {
        display: flex;
        flex-direction: column;
      } */
      .testimonial {
        display: flex;
        /* margin: 2em; */
        padding: 2em;
        gap: 2em;
        align-items: center;
        flex: 1 1 25em;

        border-radius: 0.5em;
      }

      #first {
        background-color: #e0e4f3;
      }
      #second {
        background-color: #ffffff;
      }

      #third {
        background-color: #bed3f4;
      }
    }

    #contact-and-fees {
      display: flex;
      flex-direction: column-reverse;
      /* gap: 2em; */
      align-items: center;

      .contact-and-fees {
        display: flex;
        flex-direction: column;
        gap: 1em;
      }

      .contact-and-fees--wrapper {
        display: flex;
        flex-direction: column;
        margin: 2em;
        padding: 2em;
        border-radius: 0.5em;
        /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
      }

      .calendar-inline {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      }

      .list--bullet {
        display: flex;
        align-items: baseline;
      }

      .bullet--small {
        gap: 2em;

        img {
          width: 3em;
        }
      }

      .bullet--large {
        align-items: center;
        gap: 3em;

        img {
          width: 7.5em;
        }
      }

      #contact {
        background-color: #f5f5f5;
      }

      #urgent-help {
        background-color: #fdebea;
      }

      .contact-point {
        display: flex;
        align-items: center;
        gap: 1em;
      }
    }

    #credentials {
      display: flex;
      justify-content: center;
      width: 100%;

      justify-content: space-evenly;
      gap: 2em;

      .credential-logo {
        height: 20em;
      }
    }
  }

  /* Desktop Style */
  &.desktop {
    main {
      #topics {
        /* width: 80%; */

        .row1 {
          justify-self: end;
        }

        .row2 {
          justify-self: start;
        }
      }
      #testimonials {
        align-items: center;

        .testimonial {
          width: 60%;
        }
      }

      #contact-and-fees {
        flex-direction: row;
        justify-content: center;

        max-width: 300em;

        .contact-and-fees {
          /* max-width: 150em; */
          flex: 3;
        }

        .calendar-inline {
          /* max-width: 150em; */
          flex: 2;
          margin: 0 2em;
        }
      }
    }
  }

  footer {
    padding: 7em 0;
    display: flex;
    justify-content: center;

    #copyright {
      font-size: 2.5em;
    }
  }
`;
