import styled from "styled-components";

export default styled("header")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  justify-content: center;
  gap: 1em 5em;
  /* margin: 0 2em; */
  /* flex-direction: column; */

  position: sticky;
  top: 0;
  z-index: 5;
  background-color: #ffffff;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 2px 2px -1px;

  .site--anchor {
    display: flex;
    justify-content: center;

    .site-title {
      object-fit: cover;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .nav-opt {
    font-size: 3em;

    font-weight: 500;
  }

  .site-carousel-desktop {
    display: flex;
    align-items: center;
    gap: 3em 1em;
    overflow: hidden;

    .nav-opt {
      padding: 0 1em;
      flex: 1;
      text-align: center;
    }
  }

  /* Desktop Style */
  &.desktop {
    padding-bottom: 2em;

    .mobile {
      display: none !important;
    }

    .site--anchor {
      flex: 5;
      max-width: 100em;

      .site-title {
        /* min-width: 40em; */
        /* max-width: 100em; */
      }
    }

    .contact--anchor {
      /* flex: 2; */
      margin: 0 1em;
      /* font-size: 2em; */
    }

    .spacer {
      /* flex: 1; */
    }

    .site-navigation {
      display: flex;
      justify-content: center;

      width: 100%;

      .site-carousel-desktop {
        /* flex: 1; */
        /* width: 200em; */
      }
    }
  }

  &.mobile {
    .desktop {
      position: fixed;
      right: 105vw;
      height: 0;
    }

    .site--anchor {
      flex: 1;
    }

    .contact--anchor {
      position: absolute;
      bottom: 1.25em;
      right: 1.25em;
      z-index: 1;
    }

    .contact--anchor.behind {
      z-index: 0;
    }

    .site-navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 16em;
      /* flex: 1; */
      width: 100%;
    }

    .site-carousel-mobile {
      display: flex;

      z-index: 10;
    }

    .site-navigation li {
      list-style: none;
    }

    .site-navigation a {
      text-decoration: none;
      color: #283278;
      font-weight: 500;
      font-size: 1em;
      padding: 0.7em;
    }

    .site-navigation a:hover {
      font-weight: bolder;
    }

    .site-navigation {
      display: block;
      position: relative;
      height: 15em;
    }

    .site-navigation .checkbox {
      position: absolute;
      display: block;
      height: 3em;
      width: 3em;
      top: 50%;
      transform: translateY(-50%);
      left: 2em;
      z-index: 5;
      opacity: 0;
      cursor: pointer;
    }

    .site-navigation .hamburger-lines {
      display: block;
      height: 5em;
      width: 6.5em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 4em;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .site-navigation .hamburger-lines .line {
      display: block;
      height: 0.4em;
      width: 100%;
      border-radius: 2em;
      background: #283278;
    }

    .site-navigation .hamburger-lines .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }

    .site-navigation .hamburger-lines .line2 {
      transition: transform 0.2s ease-in-out;
    }

    .site-navigation .hamburger-lines .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }

    .site-carousel-mobile {
      padding-top: 5px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 2px 2px -1px;

      width: 100%;
      transform: translate(-150%);
      display: flex;
      flex-direction: column;
      transition: transform 0.5s ease-in-out;
      text-align: center;

      height: 60vh;
      justify-content: space-around;
      border-radius: 1em;

      background-color: white;
    }

    .site-carousel-mobile li {
      margin-bottom: 0.8em;
    }

    .site-navigation input[type="checkbox"]:checked ~ .site-carousel-mobile {
      transform: translateX(0);
    }

    .site-navigation input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
      transform: rotate(45deg);
    }

    .site-navigation input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
      transform: scaleY(0);
    }

    .site-navigation input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
      transform: rotate(-45deg);
    }

    .site-navigation input[type="checkbox"]:checked ~ .logo {
      display: none;
    }
  }
`;
