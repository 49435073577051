import styled from "styled-components";

export default styled("div")`
  min-width: 0px;

  border-radius: 0.5em;
  padding: 1em 2em;
  margin: 1em;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  display: flex;
  flex-direction: column;

  max-width: 100em;

  .topic-header--wrapper {
    display: flex;
    display: -webkit-flex;
    cursor: pointer;
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }

  .topic-header {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0 1em; */
  }

  .topic-content--wrapper {
    transition: height 500ms ease-in-out;
    overflow: hidden;
    /* padding: 0 2em; */
  }

  .topic-expand--btn {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: inherit;
    box-shadow: none;
    padding: 0;

    img {
      height: 4em;
    }
  }
`;
